<template>
    <div>
        <el-input placeholder="请输入" style="width: 200px;">
            <i slot="suffix" class="el-icon-error"></i>
        </el-input>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="textarea">
            </el-input>


        <div class="main">
            <span>Botton:</span>
                <div>
                    <span>基础：</span>
                    <fm-button type="primary" @click="open1">填充按钮</fm-button>
                    <fm-button type="info" @click="showDialog2=true">按钮</fm-button>
                    <fm-button type="danger" @click="showDialog3=true">按钮</fm-button>
                </div>
                <div>
                    <span>plain：</span>
                    <fm-button type="primary" plain>按钮</fm-button>
                    <fm-button type="info" plain>按钮</fm-button>
                </div>
                <div>
                    <span>round：</span>
                    <fm-button type="primary" round>按钮</fm-button>
                    <fm-button type="info" round>按钮</fm-button>
                    <fm-button type="danger" round>按钮</fm-button>
                </div>
                <div>
                    <span>round+plain：</span>
                    <fm-button type="primary" plain round>按钮</fm-button>
                    <fm-button type="info" plain round>按钮</fm-button>
                </div>
                <div>
                    <span>禁用：</span>
                    <fm-button type="primary" disabled>按钮</fm-button>
                    <fm-button type="info" disabled>按钮</fm-button>
                    <fm-button type="danger" disabled>按钮</fm-button>
                    <fm-button type="primary" plain disabled>按钮</fm-button>
                    <fm-button type="info" plain disabled>按钮</fm-button>
                </div>
                <div>
                    <span>大小：</span>
                    <div >
                        <fm-button type="primary">按钮</fm-button>
                        <fm-button type="primary" size="medium">按钮</fm-button>
                        <fm-button type="info" size="small">按钮</fm-button>
                        <fm-button type="danger" size="mini">按钮</fm-button>
                    </div>
                    <br/>
                    <div>
                        <fm-button type="primary" round>按钮</fm-button>
                        <fm-button type="primary" round size="medium">按钮</fm-button>
                        <fm-button type="info" round size="small">按钮</fm-button>
                        <fm-button type="danger" round size="mini">按钮</fm-button>
                    </div>
                    <div>
                        <fm-button type="primary" plain>按钮</fm-button>
                        <fm-button type="primary" plain size="medium">按钮</fm-button>
                        <fm-button type="info" plain size="small">按钮</fm-button>
                        <fm-button type="info" plain size="mini">按钮</fm-button>
                    </div>
                </div>
                <div>
                    <span>虚线：</span>
                    <fm-button type="primary" dashed>按钮</fm-button>
                    <fm-button type="info" dashed>按钮</fm-button>
                    <fm-button type="primary" round dashed>按钮</fm-button>
                    <fm-button type="info" round dashed>按钮</fm-button>
                    <fm-button type="primary" disabled dashed>按钮</fm-button>
                    <fm-button type="info" disabled dashed>按钮</fm-button>
                </div>
                <div>
                    <span>文本：</span>
                    <fm-button type="primary" text>按钮</fm-button>
                    <fm-button type="info" text>按钮</fm-button>
                    <fm-button type="primary" round text>按钮</fm-button>
                    <fm-button type="info" round text>按钮</fm-button>
                    <fm-button type="primary" disabled text>按钮</fm-button>
                    <fm-button type="info" disabled text>按钮</fm-button>
                </div>

                <div>
                    <span>图标：</span>
                    <fm-button type="primary" icon="icon-big">按钮</fm-button>
                    <fm-button type="info" icon="icon-big">按钮</fm-button>

                    <fm-button type="primary" icon="icon-big" disabled>按钮</fm-button>
                    <fm-button type="info" icon="icon-big" disabled>按钮</fm-button>
                </div>
                <div>
                    <fm-button type="primary" plain icon="icon-big">按钮</fm-button>
                    <fm-button type="info" plain icon="icon-big">按钮</fm-button>
                    <fm-button type="primary" plain icon="icon-big" disabled>按钮</fm-button>
                    <fm-button type="info" plain icon="icon-big" disabled>按钮</fm-button>
                </div>

                <div>
                    <fm-button type="primary" dashed icon="icon-big">按钮</fm-button>
                    <fm-button type="info" dashed icon="icon-big">按钮</fm-button>
                    <fm-button type="primary" dashed icon="icon-big" disabled>按钮</fm-button>
                    <fm-button type="info" dashed icon="icon-big" disabled>按钮</fm-button>
                </div>

                <div>
                    <fm-button type="primary" text icon="icon-big">按钮</fm-button>
                    <fm-button type="info" text icon="icon-big">按钮</fm-button>
                    <fm-button type="primary" text icon="icon-big" disabled>按钮</fm-button>
                    <fm-button type="info" text icon="icon-big" disabled>按钮</fm-button>
                </div>


                <div>
                    <fm-button type="primary" size="medium" icon="icon-big">按钮</fm-button>
                    <fm-button type="info" size="small" icon="icon-big">按钮</fm-button>
                    <fm-button type="primary" size="mini" icon="icon-big" disabled>按钮</fm-button>
                </div>

                <div>
                    <span>图标按钮：</span>
                    <fm-button type="primary" size="medium" icon="icon-big"></fm-button>
                    <fm-button type="info" size="small" icon="icon-big"></fm-button>
                    <fm-button type="primary" size="mini" icon="icon-big" disabled></fm-button>
                </div>
                <div>
                    <fm-button type="primary" text icon="icon-big"></fm-button>
                    <fm-button type="info" text icon="icon-big"></fm-button>
                    <fm-button type="primary" text icon="icon-big"></fm-button>
                </div>
                <div>
                    <fm-button type="primary" plain icon="icon-big"></fm-button>
                    <fm-button type="info" plain icon="icon-big"></fm-button>
                    <fm-button type="primary" plain icon="icon-big"></fm-button>
                </div>
                <div>
                    <fm-button type="primary" circle text icon="icon-big"></fm-button>
                    <fm-button type="info" circle text icon="icon-big" size="medium"></fm-button>
                    <fm-button type="primary" circle text icon="icon-big" size="small"></fm-button>
                    <fm-button type="primary" circle text icon="icon-big" size="mini"></fm-button>
                </div>
        </div>
        <base-dialog :isShow="showDialog" @handleClose="showDialog=false"></base-dialog>
        <title-dialog :isShow="showDialog2" @handleClose="showDialog2=false"></title-dialog>
        <input-dialog :isShow="showDialog3" @handleClose="showDialog3=false"></input-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog'
import TitleDialog from '@/components/Dialog/TitleDialog'
import InputDialog from '@/components/Dialog/InputDialog'
export default {
    components: { BaseDialog,TitleDialog,InputDialog },
    data(){
        return{
            showDialog:false,
            showDialog2:false,
            showDialog3:false
        }
    },
    methods:{
        open1(){
            this.showDialog=true
        }
    }
}
</script>

<style lang="scss">
    .main{
        .fm-button{
            margin-right:8px;
        }
        div{
            margin-bottom: 20px;
            display: flex;
        }
    }
    
</style>